@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*,
*::after,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
label {
  font-family: 'Roboto', sans-serif;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  @apply w-2;
}

::-webkit-scrollbar-track {
  @apply bg-gray-200;
}

::-webkit-scrollbar-thumb {
  @apply bg-secondary bg-opacity-30 rounded-md;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-opacity-45;
}

.faded-container {
  @apply bg-primary-light bg-opacity-70;
}

.top-faded-container {
  @apply bg-primary-light bg-opacity-80;
}

.faded-container .scrollable::-webkit-scrollbar-track {
  @apply bg-inherit;
}

.faded-container .scrollable::-webkit-scrollbar-thumb {
  @apply bg-primary-light bg-opacity-80;
}
