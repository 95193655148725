.root {
  @apply text-center relative shrink-0 snap-start bg-primary-light rounded-2xl;
}

.cardMedia {
  @apply h-48 sm:h-36 rounded-t-2xl bg-white;
}

.cardHeader {
  @apply flex justify-between gap-2 items-center;
}

.cardHeader > svg path {
  stroke-width: 3 !important;
}

.cardTitle {
  @apply text-sm text-white truncate;
}

.menuIcon {
  @apply w-4 h-4 text-white ring-1 ring-white rounded-full;
}

.dropdownMenu span {
  @apply text-base;
}

.dropdownMenu svg {
  @apply text-xl text-secondary;
}

.cardDescription {
  @apply text-xs font-thin text-white line-clamp-2;
}

.cardBtn {
  @apply !bg-white !text-primary hover:!bg-accent;
}

.cardBtn > span {
  @apply text-sm font-bold;
}

.cardBtn > svg {
  @apply text-lg;
}
