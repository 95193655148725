.base {
  @apply shrink-0 py-3 px-3 md:px-6;
}

.flexCenter {
  @apply flex items-center;
}

.inner {
  @apply flexCenter justify-between h-full gap-4;
}

.titleContainer {
  @apply flexCenter gap-4 md:min-w-[15%];
}

.title {
  @apply text-2xl font-bold;
}

.right {
  @apply flexCenter gap-4;
}

.search.close {
  @apply hidden md:flex;
}

.search {
  @apply flex flex-grow gap-4 w-full justify-between items-start pt-4 px-5 absolute z-20 inset-0 bg-white md:gap-0 md:p-0 md:justify-center md:static;
}

.searchBar {
  @apply w-full lg:min-w-72 md:max-w-[400px] flex-1;
}

.searchInput {
  @apply !bg-white !ring-secondary/25;
}

.extend {
  @apply w-10 h-10 cursor-pointer ring-1 ring-secondary/25 shrink-0 bg-white;
}

.iconBox {
  @apply extend rounded-full overflow-hidden flex items-center justify-center transition-colors duration-300;
}

.iconBox > svg {
  @apply text-2xl;
}

.iconBox > img {
  @apply w-full object-cover h-full;
}

.iconBox.active {
  @apply bg-primary-light;
}

.iconBox.active > svg {
  @apply text-white;
}

.avatar {
  @apply extend;
}

.notificationCount {
  @apply absolute -top-1 -right-1 min-w-4 h-4 rounded-full bg-red-700 text-white text-xs flex items-center justify-center;
}
