.root {
  @apply flex gap-1 rounded-full ring-1;
}

.filled.primary {
  @apply bg-primary-light text-white ring-primary-light;
}

.outlined.primary {
  @apply ring-primary-light/25;
}

.filled.secondary {
  @apply bg-secondary text-white ring-secondary;
}

.outlined.secondary {
  @apply ring-secondary/25;
}

.medium {
  @apply px-2 py-1;
}

.small {
  @apply p-1;
}

.label {
  @apply text-xs truncate;
}

.deleteIcon {
  @apply text-sm cursor-pointer;
}

.outlined .deleteIcon {
  @apply text-primary-light;
}

.filled .deleteIcon {
  @apply text-white;
}
