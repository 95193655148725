.header {
  @apply flex flex-col md:flex-row justify-between items-center gap-2 mb-4;
}

.header svg {
  @apply text-xl;
}

.templatesContainer {
  @apply grid grid-cols-[repeat(auto-fill,minmax(17rem,1fr))] gap-5;
}

.filterContainer {
  @apply flex gap-4 items-center;
}
