.base {
  @apply flex flex-col w-0 md:w-1/4 md:min-w-[19rem] md:max-w-[19rem] overflow-y-auto shrink-0 bg-primary-light;
}

.header {
  @apply shrink-0 mx-auto w-full pt-5 px-6;
}

.content {
  @apply flex-1 flex flex-col overflow-y-auto py-5 px-6;
}

.content::-webkit-scrollbar-track {
  @apply bg-primary-light;
}

.base.show {
  @apply w-full;
}

.nav {
  @apply flex-1 flex flex-col gap-2 mb-2;
}
