.carouselContainer {
  @apply relative flex flex-nowrap overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-0 h-full;
}

.controls {
  @apply flex;
}

.controls.center {
  @apply absolute inset-0 flex items-center z-10 px-2;
}

.controls.base {
  @apply gap-2;
}

.controls.between {
  @apply justify-between;
}

.controls.outer {
  @apply -mx-2 p-0;
}

.indicatorsContainer {
  @apply px-1 absolute bottom-2 left-0 right-0 flex justify-center gap-2 z-20 flex-wrap;
}

.dot {
  @apply w-[6px] h-[6px] rounded-full ring-1 ring-white bg-white transition-opacity duration-300;
}

.dot.active {
  @apply bg-primary-light  ring-accent;
}
