.root {
  @apply flex items-center justify-center relative  overflow-hidden select-none text-[1.25rem];
}

.root img {
  @apply w-full h-full object-cover;
}

.circle {
  @apply rounded-[50%];
}

.square {
  @apply rounded-none;
}

.rounded {
  @apply rounded-md;
}

.icon {
  @apply w-[60%] h-[60%] text-lg;
}
