.menu {
  @apply absolute z-10 min-w-32 rounded-lg shadow-lg bg-white p-1 ring-1 ring-opacity-10 ring-primary;
}

.menu > .inner {
  @apply flex flex-col gap-1 justify-start;
}

.menu.right {
  @apply right-0;
}

.menu.left {
  @apply left-0;
}

.menu.top-right {
  @apply origin-top-right;
}

.menu.top-left {
  @apply origin-top-left;
}

.menu.bottom-right {
  @apply origin-bottom-right;
}

.menu.bottom-left {
  @apply origin-bottom-left;
}
