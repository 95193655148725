.tabPanel {
  @apply grid grid-cols-[repeat(auto-fill,minmax(7rem,1fr))] gap-3;
}

.avatarBox {
  @apply h-32 w-28  border-[1px] rounded-2xl overflow-hidden border-gray-200;
}

.avatarBox > img {
  @apply w-full h-full object-cover;
}

.dividerBox {
  @apply flex justify-center items-center my-2;
}

.dividerBox > .divider {
  @apply flex-1 h-[2px] bg-gray-100;
}
