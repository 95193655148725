.cardImage {
  @apply h-full w-full overflow-hidden rounded-lg relative;
}

.cardImage > img {
  @apply h-full w-full object-cover;
}

.cardImage > .cardImageOverlay {
  @apply h-full w-full backdrop-blur-sm flex justify-center items-center rounded-lg absolute inset-0;
}

.cardImageOverlay > .icon {
  @apply rounded-full bg-white/40 p-3;
}

.cardImageOverlay.darkBackdrop {
  @apply bg-black bg-opacity-40;
}

.cardImageOverlay.noBg > .icon {
  @apply bg-transparent;
}

.icon {
  @apply text-3xl text-white;
}
