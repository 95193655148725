.root {
  @apply shrink-0 px-6 py-4 flex items-center justify-between;
}

.icon {
  @apply w-5 h-5 cursor-pointer;
}

.titleContainer {
  @apply flex items-center gap-4;
}

.title {
  @apply font-bold;
}
