.root {
  @apply flex justify-between items-center w-full;
}

.vertical {
  @apply flex-col;
}

.horizontal {
  @apply flex-row;
}
