.menuItem {
  @apply flex items-center gap-3 w-full text-left text-sm rounded-md py-1 px-2 cursor-pointer;
}

.menuItem.light {
  @apply hover:bg-gray-100;
}

.menuItem.dark {
  @apply hover:bg-zinc-800;
}

.menuItem > .icon {
  @apply w-4 h-4 ml-2 text-gray-500;
}

.menuItem > .label {
  @apply flex-grow;
}

.light.active {
  @apply bg-gray-100;
}

.dark.active {
  @apply bg-zinc-800;
}

.active .label {
  @apply font-medium;
}
