.root {
  @apply fixed inset-0 z-40 flex items-center justify-center w-full h-full bg-black;
}

.root.hide {
  @apply hidden;
}

.root.opacity0 {
  @apply bg-opacity-0;
}

.root.opacity25 {
  @apply bg-opacity-25;
}

.root.opacity50 {
  @apply bg-opacity-50;
}

.root.opacity75 {
  @apply bg-opacity-75;
}

.root.opacity100 {
  @apply bg-opacity-100;
}

.inner {
  @apply relative z-50 w-full max-h-[95%] mx-auto rounded-2xl shadow-lg flex flex-col;
}

.inner.white {
  @apply bg-white text-primary-light;
}

.inner.primary {
  @apply bg-primary-light text-white;
}

.inner.sky {
  @apply bg-gray-900 bg-opacity-60 text-white;
}

.inner.dark {
  @apply text-white;
}

.inner.sm {
  @apply max-w-sm;
}

.inner.md {
  @apply max-w-md;
}

.inner.lg {
  @apply max-w-lg;
}

.inner.xl {
  @apply max-w-xl;
}

.inner.xxl {
  @apply max-w-2xl;
}

.inner.xxxl {
  @apply max-w-3xl;
}

.inner.xxxxl {
  @apply max-w-4xl;
}

.inner.xxxxxl {
  @apply max-w-5xl;
}

.inner.xxxxxxl {
  @apply max-w-6xl;
}

.inner.xxxxxxxl {
  @apply max-w-7xl;
}

.inner.full {
  @apply max-w-[80%];
}
