.root {
  @apply flex flex-col border-dashed border-[1px] border-separate min-h-52 rounded-lg p-2 border-primary;
}

.root.dragging {
  @apply border-accent;
}

.label {
  @apply flex-1 flex flex-col gap-3 items-center justify-center h-full bg-gray-100 rounded-lg cursor-pointer;
}

.iconBox {
  @apply bg-white rounded-lg p-3;
}

.iconBox > svg {
  @apply text-3xl;
}

.file {
  @apply bg-white rounded-full py-2 px-4 flex items-center gap-2 cursor-pointer transition-shadow duration-300 ease-in-out;
}

.file:hover {
  @apply shadow-lg;
}

.file > p {
  @apply text-xs truncate;
}
