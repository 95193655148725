.base {
  @apply flex items-center gap-4 py-3 px-4 rounded-xl text-gray-300;
}

.child:hover {
  @apply font-bold;
}

.peer:hover {
  @apply bg-gray-300/15 text-accent;
}

.base svg {
  @apply text-xl text-gray-300;
}

.peer.activeBg {
  @apply bg-gray-300/15 text-accent;
}

.peer.activeBg svg {
  @apply text-accent;
}

.peer:hover svg {
  @apply text-accent;
}

.child.activeText {
  @apply font-bold text-accent;
}
