.root {
  @apply absolute z-50 bg-white shadow-lg p-5 rounded-xl max-w-80 flex flex-col gap-3;
}

.header {
  @apply flex justify-between items-center;
}

.header h5 {
  @apply text-lg font-bold;
}

.header svg {
  @apply text-xl cursor-pointer;
}

.fContainer {
  @apply flex flex-wrap gap-2 p-1;
}

.selectedFiltersContainer {
  @apply fContainer mt-3;
}

.body {
  @apply flex-1 overflow-y-auto flex flex-col gap-4;
}

.body h6 {
  @apply font-bold mb-3;
}

.footer {
  @apply flex gap-2;
}

.footer > button:first-child {
  @apply w-1/3;
}

.footer > button:last-child {
  @apply flex-1;
}
