.root {
  @apply flex items-center gap-2 justify-center font-bold disabled:opacity-55 disabled:cursor-not-allowed transition duration-200 ease-in-out;
}

/* radius */
.root.radius-sm {
  @apply rounded-sm;
}

.root.radius-md {
  @apply rounded-md;
}

.root.radius-lg {
  @apply rounded-lg;
}

.root.radius-xl {
  @apply rounded-xl;
}

.root.radius-2xl {
  @apply rounded-2xl;
}

.root.radius-full {
  @apply rounded-full;
}

/* size */
.base:not(.noPadding) {
  @apply px-4 py-3;
}

.xs:not(.noPadding) {
  @apply p-1;
}

.sm:not(.noPadding) {
  @apply p-2;
}

.lg:not(.noPadding) {
  @apply px-6 py-3;
}

/* color */
.text.primary {
  @apply bg-transparent text-primary;
}

.text.primary-light {
  @apply bg-transparent text-primary-light;
}

.text.accent {
  @apply bg-transparent text-accent;
}

.text.secondary {
  @apply bg-transparent text-secondary;
}

.text.white {
  @apply bg-transparent text-white;
}

.text.darkGray {
  @apply bg-transparent text-secondary;
}

.filled.primary {
  @apply bg-primary text-white;
}

.filled.primary-light {
  @apply bg-primary-light text-white;
}

.filled.accent {
  @apply bg-accent text-white;
}

.filled.white {
  @apply bg-white text-primary;
}

.filled.error {
  @apply bg-red-600 text-white;
}

.filled.secondary {
  @apply bg-secondary text-white;
}

.filled.darkGray {
  @apply bg-zinc-800 text-white;
}

.outlined.primary {
  @apply ring-1 ring-primary text-primary;
}

.outlined.primary-light {
  @apply ring-1 ring-primary-light text-primary-light;
}

.outlined.accent {
  @apply ring-1 ring-accent text-accent;
}

.outlined.white {
  @apply ring-1 ring-white text-primary;
}

.outlined.error {
  @apply ring-1 ring-red-600;
}

.outlined.secondary {
  @apply ring-1 ring-secondary text-secondary;
}

.outlined.darkGray {
  @apply ring-1 ring-zinc-800 text-zinc-800;
}

.fullWidth {
  @apply w-full;
}
