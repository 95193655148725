/* main container */
.root {
  @apply flex gap-2 rounded-xl relative;
}

.column {
  @apply flex-col;
}
/* checkbox or radio container */
.chkRad {
  @apply flex-row-reverse items-center justify-end;
}

/* input container */
.inputContainer {
  @apply flex gap-2 items-center overflow-hidden;
}

.root:not(.chkRad) > .inputContainer {
  @apply rounded-xl;
}

.textInput {
  @apply border-none bg-neutral-100 ring-1 ring-neutral-100;
}

.textInput.focus {
  @apply ring-secondary;
}

.textInput.error {
  @apply ring-red-500;
}

.inputContainer span:first-child {
  @apply shrink-0 flex items-center justify-center ml-3;
}

.inputContainer span:last-child {
  @apply shrink-0 flex items-center justify-center mr-3;
}

/* label */
.label {
  @apply text-xs text-primary-light font-medium;
}

.label.error {
  @apply text-red-500;
}

/* input */
.input {
  @apply flex-1  bg-inherit placeholder:text-sm text-secondary;
}

.checkbox {
  @apply w-4 h-4 ring-gray-400 rounded accent-primary;
}

.input:not(.checkbox) {
  @apply min-w-16 border-0 outline-none focus:ring-0 py-2 px-3;
}

/* helper text */
.helperText {
  @apply ml-2 text-xs text-gray-500;
}

/* error message */
.helperText.error {
  @apply text-xs text-red-500;
}
